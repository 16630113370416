import { useStore as useGeneralHealthStore } from './general-health-store';
import shallow from 'zustand/shallow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function QuestionText({ id, question }) {
  const [
    generalHealthAnswers,
    onGeneralHealthDataChange,
  ] = useGeneralHealthStore(
    (store) => [
      store.generalHealthAnswers,
      store.onGeneralHealthDataChange,
    ],
    shallow,
  );

  const value = generalHealthAnswers[id]?.value;

  const handleInputChange = (e) => {
    onGeneralHealthDataChange({ id, value: e.target.value });
  };

  return (
    <>
      <Typography variant="body2">
        <strong>
          {question}
        </strong>
      </Typography>
      <TextField variant="standard"
                 size="small"
                 margin="normal"
                 autoComplete="off"
                 value={value || ''}
                 onChange={handleInputChange}
      />
    </>
  );
}