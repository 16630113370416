import { useEffect, useState } from 'react';
import { useStore as useTBStore } from './tuberculosis-store';
import shallow from 'zustand/shallow';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SingleAttachmentOld from './single-attachment-old';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import CustomDatePicker from './custom-date-picker';

const TYPE = 'Skin';

const initialState = {
  dateAdministered: null,
  dateRead: null,
  result: '',
  value: '',
  attachment: '',
  attachmentObjectUrl: null,
};

export default function TuberculosisSkinTestDialog() {
  const [
    openedTbSkinTestIndex,
    setOpenedTbSkinTestIndex,
    { tbTests = {} } = {},
    onTbHistoryChange,
  ] = useTBStore(
    (store) => [
      store.openedTbSkinTestIndex,
      store.setOpenedTbSkinTestIndex,
      store.tbHistoryData,
      store.onTbHistoryChange,
    ],
    shallow,
  );

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (openedTbSkinTestIndex != null) {
      setState({
        ...initialState,
        ...(tbTests.skin?.[openedTbSkinTestIndex] || {}),
      });
    }
  }, [openedTbSkinTestIndex, tbTests]);

  const handleChange = (valuesToMerge) => {
    setState({
      ...state,
      ...valuesToMerge,
    });
  };

  const handleClose = () => {
    setOpenedTbSkinTestIndex(null);
    setState(initialState);
  };

  const handleSave = async () => {
    tbTests.skin[openedTbSkinTestIndex] = {
      ...state,
      type: TYPE,
    };
    onTbHistoryChange({
      tbTests: {
        ...tbTests,
        skin: [
          ...tbTests.skin,
        ]
      }
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose}
            open={openedTbSkinTestIndex != null}
            maxWidth="md"
            transitionDuration={{ enter: 225, exit: 0 }}
    >
      <DialogTitle>
        {tbTests.skin?.[openedTbSkinTestIndex] ? 'Edit' : 'Add'} TB (Tuberculin) Skin Test
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" justifyContent="flex-start" spacing={3} marginX={1}>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Date Administered:
            </Typography>
            <CustomDatePicker value={state.dateAdministered}
                              disableFuture
                              handleInput={(newValue) => {
                                handleChange({ dateAdministered: newValue })
                              }}
            />
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Date Read: *
            </Typography>
            <CustomDatePicker value={state.dateRead}
                              disableFuture
                              handleInput={(newValue) => {
                                handleChange({ dateRead: newValue })
                              }}
            />
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Result: *
            </Typography>
            <TextField select
                       variant="standard"
                       margin="dense"
                       size="small"
                       value={state.result || ''}
                       onChange={(e) => handleChange({ result: e.target.value })}
            >
              <MenuItem value="Positive">Positive</MenuItem>
              <MenuItem value="Negative">Negative</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Induration diameter in millimeters: *
            </Typography>
            <TextField variant="standard"
                       margin="dense"
                       size="small"
                       placeholder="e.g. 0, <5, 5*5"
                       InputProps={{
                         endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                       }}
                       value={state.value || ''}
                       onChange={(e) => handleChange({ value: e.target.value.replace(/[^0-9.*]/g, '') })}
            />
          </Stack>
          <SingleAttachmentOld handleChange={handleChange}
                               attachmentKey="attachment"
                               attachment={state.attachment || ''}
                               type="tb-tests"
                               objectUrl={state.attachmentObjectUrl}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}
                size="small"
                variant="text"
                color="neutral"
        >
          Discard and close
        </Button>
        <LoadingButton variant="text"
                       onClick={handleSave}
                       disabled={!state.result && !state.attachment && !state.value && !state.dateAdministered && !state.dateRead}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
