import create from 'zustand';
import { getAnswers, getAttachments, getQuestions, saveAnswers, saveAttachments } from '../../api/general-health';
import mime from 'mime';

const formatAnswers = (answers, questions) => {
  const questionTypes = {};
  questions?.forEach(({ id, type, options }) => {
    questionTypes[id] = { type, options };
  });
  const formattedAnswers = {};
  Object.entries(answers).forEach(([questionId, { id, value }]) => {
    let formattedValue = value;
    const questionInfo = questionTypes[questionId];
    if (questionInfo?.type === 'multiChoice') {
      if (questionInfo?.options?.length) {
        formattedValue = value?.split('\n') || [];
      } else {
        formattedValue = (typeof value === 'string' && value.toLowerCase() === 'no')
          ? false
          : !!value;
      }
    }
    formattedAnswers[questionId] = {
      id,
      value: formattedValue,
    };
  });
  return formattedAnswers;
};

const unformatAnswers = (answers) => {
  const res = {};
  Object.entries(answers).forEach(([questionId, { value, id }]) => {
    let formattedValue = value;
    if (Array.isArray(value)) {
      const values = [...value];
      values.sort();
      formattedValue = values.join('\n');
    } else if (value === true) {
      formattedValue = 'Yes';
    } else if (value === false) {
      formattedValue = 'No';
    }
    res[questionId] = {
      id,
      value: formattedValue,
    };
  });
  return res;
};

const getGeneralHealthAttachments = async () => {
  const res = await getAttachments();
  return res.map((key) => {
    const _slices = key.split('/');
    const name = _slices[_slices.length - 1];
    const fileDir = _slices[_slices.length - 3];
    return {
      key,
      fileDir,
      value: {
        name: key?.split('/').pop(),
        type: mime.getType(name.split('.').pop()),
      },
    };
  });
};

const useStore = create((set, get) => ({
  generalHealthQuestions: [],
  generalHealthAnswers: {},

  generalHealthAttachments: [],
  generalHealthAttachmentsNew: [],

  fetchGeneralHealthData: async () => {
    const questions = await getQuestions();
    const answers = await getAnswers();
    const generalHealthAttachments = await getGeneralHealthAttachments();
    const formattedAnswers = formatAnswers(answers, questions);
    set({
      generalHealthQuestions: questions,
      generalHealthAnswers: formattedAnswers,
      generalHealthAttachments,
    });
  },

  onGeneralHealthDataChange: ({ id, value }) => set(({ generalHealthAnswers }) => {
    return {
      generalHealthAnswers: {
        ...generalHealthAnswers,
        [id]: {
          id: generalHealthAnswers[id]?.id,
          value,
        },
      },
    };
  }),

  handleSingleAttachmentInput: (file, fileDir) => set(({ generalHealthAttachmentsNew }) => {
    return {
      generalHealthAttachmentsNew: file
        ? [
          ...generalHealthAttachmentsNew,
          {
            file,
            fileDir,
            objectUrl: URL.createObjectURL(file),
          },
        ]
        : generalHealthAttachmentsNew.filter((curr) => curr.fileDir !== fileDir),
    };
  }),

  submit: async () => {
    const { generalHealthAnswers, fetchGeneralHealthData, generalHealthAttachmentsNew } = get();
    const unformattedAnswers = unformatAnswers(generalHealthAnswers);
    await saveAnswers(unformattedAnswers);
    if (generalHealthAttachmentsNew.length) {
      await saveAttachments(generalHealthAttachmentsNew.map(({ file, fileDir }) => ({ file, fileDir })));
      generalHealthAttachmentsNew.forEach(({ objectUrl }) => {
        URL.revokeObjectURL(objectUrl);
      });
      set({ generalHealthAttachmentsNew: [] }); // ?
    }
    await fetchGeneralHealthData();
  },
}));

export { useStore };
