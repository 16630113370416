import { useHistory, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from '../store';
import shallow from 'zustand/shallow';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

export default function Profile() {
  const history = useHistory();

  const [
    personData,
    fetchPersonData,
    clearUserToken,
  ] = useStore(
    (store) => [
      store.personData,
      store.fetchPersonData,
      store.clearUserToken,
    ],
    shallow,
  );

  useEffect(() => {
    fetchPersonData().catch(() => {
      history.replace('/');
    });
  }, [history, fetchPersonData]);

  const {
    id,
    name,
    email,
    role,
    birthDate,
    campusLocation,
    livesAtCampus,
    attendsClassesAtCampus,
  } = personData || {};

  const getFormattedBirthDate = () => {
    if (!birthDate) return '-';
    const date = new Date(birthDate);
    return new Date(date.valueOf() + date.getTimezoneOffset() * 60000).toLocaleDateString();
  };

  return (
    <Container maxWidth="md">
      {
        !!personData &&
        <Card variant="outlined" sx={{ m: 2 }}>
          <CardHeader
            title={name}
            subheader={role}
            action={
              <Link to="/sign-in">
                <Button variant="text" onClick={clearUserToken}>
                  Sign Out
                </Button>
              </Link>
            }
          />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                ID:
              </Grid>
              <Grid item xs={10}>
                {id}
              </Grid>

              <Grid item xs={2}>
                Email:
              </Grid>
              <Grid item xs={10}>
                {email || '-'}
              </Grid>

              <Grid item xs={2}>
                Date of Birth:
              </Grid>
              <Grid item xs={10}>
                {getFormattedBirthDate()}
              </Grid>

              <Grid item xs={2}>
                Campus Location:
              </Grid>
              <Grid item xs={10}>
                {campusLocation || '-'}
              </Grid>

              <Grid item xs={2}>
                Lives at campus:
              </Grid>
              <Grid item xs={10}>
                {livesAtCampus ? 'Yes' : 'No'}
              </Grid>

              <Grid item xs={2}>
                Attends classes at campus:
              </Grid>
              <Grid item xs={10}>
                {attendsClassesAtCampus ? 'Yes' : 'No'}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    </Container>
  );
}
