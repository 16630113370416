import React, { useMemo } from 'react';
import { PrismaTable } from '@paljs/admin/PrismaTable';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => queryString.parse(search), [search]);
}

const Table: React.FC<{ model: string, actions?: ('create' | 'update' | 'delete')[] }> = (props) => {
  const router = useHistory();
  const query = useQuery();

  return (
    <PrismaTable
      pagesPath="/admin/"
      actions={props.actions}
      model={props.model}
      push={router.push}
      query={query}
    />
  );
};

export default Table;
