import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from '../store';
import { useStore as useVaccinationsStore } from '../components/vaccinations-store';
import { useStore as useTBStore } from '../components/tuberculosis-store';
import { useStore as useMeningitisStore } from '../components/meningitis-store';
import { useStore as notificationStore } from '../components/notification-store';
import { useStore as useGeneralHealthStore } from '../components/general-health-information/general-health-store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import { sendLogToServer } from '../api/service';
import Instructions from '../components/instructions';
import TuberculosisQuestionnaire from '../components/tuberculosis-questionnaire';
import TuberculosisHistory from '../components/tuberculosis-history';
import MeningitisWaiver from '../components/meningitis-waiver';
import RequiredVaccinations from '../components/required-vaccinations';
import RecommendedVaccinations from '../components/recommended-vaccinations';
import Authorization from '../components/authorization';
import AttachmentDialog from '../components/attachment-dialog';
import TiterDialog from '../components/titer-dialog';
import TuberculosisBloodTestDialog from '../components/tuberculosis-blood-test-dialog';
import TuberculosisSkinTestDialog from '../components/tuberculosis-skin-test-dialog';
import GeneralHealthQuestionnaire from '../components/general-health-information/general-health-questionnaire';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getPortalSettingsItem } from '../utils';

const { VACCINATION_TYPE_ID_MENINGITIS } = consts;

const isTBSectionEnabled = getPortalSettingsItem('isTBSectionEnabled');

const allSteps = [
  {
    label: 'Instruction',
    render: () => (<Instructions/>),
  },
  {
    label: 'General health information',
    render: () => (<GeneralHealthQuestionnaire/>),
    handleSubmit: async () => {
      const { submit } = useGeneralHealthStore.getState();
      await submit();
    },
  },
  {
    label: 'Required vaccinations',
    render: () => (<RequiredVaccinations/>),
    handleSubmit: async () => {
      const { handleSubmitVaccinations } = useVaccinationsStore.getState();
      await handleSubmitVaccinations();
    },
    needToHide: (user) => {
      const { vaccinationTypes } = useVaccinationsStore.getState();
      if (!user?.livesAtCampus && !user?.attendsClassesAtCampus) {
        return true;
      }
      return !vaccinationTypes.some((vType) => {
        return (user?.role === 'Employee' && vType.requiredForEmployees)
          || (user?.role === 'Student' && vType.requiredForStudents)
          || (user?.livesAtCampus && vType.requiredForLivingAtCampus)
          || (user?.attendsClassesAtCampus && vType.requiredForAttendingClassesAtCampus);
      });
    },
  },
  {
    label: 'Recommended vaccinations',
    render: () => (<RecommendedVaccinations/>),
    handleSubmit: async () => {
      const { handleSubmitVaccinations, setIsSubmitted } = useVaccinationsStore.getState();
      await handleSubmitVaccinations();
      setIsSubmitted(true);
    },
  },
  {
    label: 'Meningitis waiver',
    render: () => (<MeningitisWaiver/>),
    handleSubmit: async () => {
      const { handleSubmitMeningitisWaiver } = useMeningitisStore.getState();
      await handleSubmitMeningitisWaiver();
    },
    needToHide: (role, vaccinationsData, meningitisWaiver, areVaccinationsSubmitted) => {
      // if (role === 'Employee') {
      //   return true;
      // }
      // const hadSubmittedAnyVaccination = Object.values(vaccinationsData).some((value) => {
      //   return typeof value.id !== 'undefined';
      // });
      const isMeningitisWaiverSigned = meningitisWaiver.waiverSigned || meningitisWaiver.waiverSignedUnder18;
      const meningitisData = vaccinationsData?.[VACCINATION_TYPE_ID_MENINGITIS];
      const isMeningitisVaccinationFullySubmitted = meningitisData?.date1 && meningitisData?.date2;
      return !(isMeningitisWaiverSigned || (areVaccinationsSubmitted && !isMeningitisVaccinationFullySubmitted));
    },
  },
  {
    label: 'Tuberculosis screening questionnaire',
    render: () => (<TuberculosisQuestionnaire/>),
    needToSkip: () => {
      const { personData } = useStore.getState();
      const { tbHistoryData } = useTBStore.getState();
      const isTreatmentFilled = Object.keys(tbHistoryData?.treatment || {}).length;
      const isTBQuestionnairePositive = (tbHistoryData?.questionnaire || []).some(x => !!x);

      const res = personData?.noNeedTbTest
        || isTreatmentFilled
        || isTBQuestionnairePositive;
      return !!res;
    },
    needToHide: () => !isTBSectionEnabled,
    handleSubmit: async () => {
      const { handleSubmitTbQuestionnaire } = useTBStore.getState();
      await handleSubmitTbQuestionnaire();
    },
  },
  {
    label: 'Tuberculosis History',
    render: () => (<TuberculosisHistory/>),
    needToSkip: () => {
      const { personData } = useStore.getState();
      const { tbHistoryData } = useTBStore.getState();
      const isTBQuestionnairePositive = (tbHistoryData?.questionnaire || []).some(x => !!x);
      const isTreatmentFilled = Object.keys(tbHistoryData?.treatment || {}).length;
      const res = (personData?.noNeedTbTest || !isTBQuestionnairePositive)
        && !isTreatmentFilled;
      return !!res;
    },
    needToHide: () => !isTBSectionEnabled,
    handleSubmit: async () => {
      const { handleSubmitTbHistory } = useTBStore.getState();
      await handleSubmitTbHistory();
    }
  },
  {
    label: 'Submit',
    render: (props) => (<Authorization {...props}/>),
    handleSubmit: async () => {
      const { handleSubmitAuthorization } = useStore.getState();
      await handleSubmitAuthorization();
    },
  },
];

export default function Vaccinations() {
  const [
    personData,
    fetchPersonData,
    isLoading,
    setIsLoading,
  ] = useStore(
    (store) => [
      store.personData,
      store.fetchPersonData,
      store.isLoading,
      store.setIsLoading,
    ],
    shallow,
  );

  const [
    areVaccinationsSubmitted,
    vaccinationsData,
    fetchVaccinationData,
    setAreExemptionFieldsValuesMissing,
  ] = useVaccinationsStore(
    (store) => [
      store.isSubmitted,
      store.vaccinationsData,
      store.fetchVaccinationData,
      store.setAreExemptionFieldsValuesMissing,
    ],
    shallow,
  );

  const [
    fetchTBData,
  ] = useTBStore(
    (store) => [
      store.fetchTBData,
    ],
    shallow,
  );

  const [
    fetchGeneralHealthData,
  ] = useGeneralHealthStore(
    (store) => [
      store.fetchGeneralHealthData,
    ],
    shallow,
  );

  const [
    meningitisWaiver,
    fetchMeningitisWaiver,
  ] = useMeningitisStore(
    (store) => [
      store.meningitisWaiver,
      store.fetchMeningitisWaiver,
    ],
    shallow,
  );

  const [
    showNotification,
  ] = notificationStore(
    (store) => [
      store.showNotification,
    ],
    shallow,
  );

  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didInitializeSuccessfully, setDidInitializeSuccessfully] = useState(false);

  const steps = useMemo(
    () => {
      return allSteps.filter(({ needToHide }) => {
        return !needToHide || !needToHide(personData, vaccinationsData, meningitisWaiver, areVaccinationsSubmitted);
      });
    },
    [personData, vaccinationsData, meningitisWaiver, areVaccinationsSubmitted]);

  const getNextStep = () => steps.findIndex((step, index) => (
    index > activeStep && (!step.needToSkip?.() && !step.needToDisable?.())
  ));

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchPersonData(),
      fetchVaccinationData(),
      fetchTBData(),
      fetchMeningitisWaiver(),
      fetchGeneralHealthData(),
    ])
      .then(() => setDidInitializeSuccessfully(true))
      .catch((e) => {
        console.error(e);
        const status = e?.response?.status;
        sendLogToServer({
          message: 'client fetching initial data',
          error: e?.response?.data?.message || e?.message || e,
          details: { stack: e?.stack, status },
        });
        showNotification({
          type: 'error',
          message: status === 401
            ? 'There is a problem with your account. Please try again later'
            : (e?.response?.data?.message || 'Unknown error'),
        });
        history.replace('/');
      })
      .finally(() => setIsLoading(false));
  }, [
    fetchVaccinationData,
    fetchPersonData,
    fetchTBData,
    fetchMeningitisWaiver,
    setIsLoading,
    showNotification,
    history,
    fetchGeneralHealthData,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handleNext = () => {
    let nextStep = getNextStep();
    if (nextStep === -1) {
      return;
    }
    setActiveStep(nextStep);
  };

  const renderStep = (stepIndex) => {
    const isFinalStep = getNextStep() === -1;
    const handleSubmit = steps[stepIndex]?.handleSubmit;

    const handleButtonClick = async () => {
      setAreExemptionFieldsValuesMissing(false);
      try {
        if (handleSubmit) {
          setIsSubmitting(true);
          await handleSubmit();
          showNotification({ type: 'success', message: 'Saved successfully' });
          setIsSubmitting(false);
        }
        if (isFinalStep) {
          setIsFinished(true);
        } else {
          handleNext();
        }
      } catch (e) {
        setIsSubmitting(false);
        const message = e.userMessage || 'Error submitting data';
        showNotification({ type: 'error', message });
        if (e.userMessage) return;
        console.error(e);
      }
    };

    const nextButton = (
      <Typography align="center">
        <LoadingButton variant="contained"
                       sx={{ mt: 3, ml: 1 }}
                       loading={isSubmitting}
                       onClick={handleButtonClick}
        >
          {
            isFinalStep ? 'Finish' :
              (handleSubmit ? 'Save and continue' : 'Next')
          }
        </LoadingButton>
      </Typography>
    );

    return (
      <>
        {steps[stepIndex].render()}
        {nextButton}
      </>
    )
  };

  const desktopSx = { display: { md: 'block', xs: 'none' } };

  const finishedPaper = (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ p: 5, maxWidth: { md: '80%', xs: '100%' } }}>
        <div dangerouslySetInnerHTML={{ __html: getPortalSettingsItem('final') }}/>
      </Paper>
    </Box>
  );

  const step = renderStep(activeStep);

  // TODO: make stepper sticky
  return (
    <Container sx={{ mb: 5, p: 2 }}>
      {
        isFinished
          ? finishedPaper
          : didInitializeSuccessfully ? (
            <Stack direction="row" spacing={8}>
              <Box sx={{ maxWidth: '100%' }}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                  {
                    steps.map(({ label, needToDisable }, index) => (
                      <Step key={index} disabled={!didInitializeSuccessfully || needToDisable?.()}>
                        <StepButton color="inherit"
                                    onClick={() => {
                                      setActiveStep(index);
                                    }}
                        >
                          {label}
                        </StepButton>
                        <StepContent sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '100%' } }}>
                          {step}
                        </StepContent>
                      </Step>
                    ))
                  }
                </Stepper>
              </Box>
              <Paper sx={{ p: 3, width: { md: '100%' }, ...desktopSx }}>
                {step}
              </Paper>
              <Box sx={desktopSx}/>
              <Box sx={desktopSx}/>
            </Stack>
          ) : null
      }
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress sx={{ color: '#fff' }}/>
      </Backdrop>
      <AttachmentDialog/>
      <TiterDialog/>
      <TuberculosisBloodTestDialog/>
      <TuberculosisSkinTestDialog/>
    </Container>
  );
}
