import { useState, useEffect, useMemo } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SingleAttachmentOld from './single-attachment-old';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomDatePicker from './custom-date-picker';

const initialState = {
  date: null,
  result: '',
  value: '',
  attachment: '',
  attachmentObjectUrl: null,
  type: '',
};

export default function TiterDialog() {
  const [
    vaccinationTypes,
    openedTiterDialogObject,
    setOpenedTiterDialogObject,
    onTitersChange,
    titers,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationTypes,
      store.openedTiterDialogObject,
      store.setOpenedTiterDialogObject,
      store.onTitersChange,
      store.titers,
    ],
    shallow,
  );

  const [state, setState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentTiterTypes = useMemo(
    () => openedTiterDialogObject
      ? vaccinationTypes?.find((curr) => curr.id === openedTiterDialogObject.vaccinationType)?.titerTypes
      : [],
    [openedTiterDialogObject, vaccinationTypes]
  );

  useEffect(() => {
    if (openedTiterDialogObject !== null) {
      const stateToEdit = titers?.[openedTiterDialogObject.index];
      setState(stateToEdit
        ? {
          ...initialState,
          isEditing: true,
          ...stateToEdit,
        }
        : { ...initialState }
      );
    }
  }, [openedTiterDialogObject, titers]);

  const handleClose = () => {
    setOpenedTiterDialogObject(null);
    setState(initialState);
    setIsSubmitting(false);
  };

  const handleSave = async () => {
    onTitersChange({
      valuesToMerge: {
        attachment: state.attachment,
        date: state.date,
        result: state.result,
        value: state.value,
        type: state.type || currentTiterTypes?.[0]?.id,
        vaccinationType: openedTiterDialogObject.vaccinationType,
        attachmentObjectUrl: state.attachmentObjectUrl,
        rid: state.rid,
      },
      index: openedTiterDialogObject.index,
    });
    handleClose();
  };

  const handleChange = (valuesToMerge) => {
    setState({
      ...state,
      ...valuesToMerge,
    });
  };

  const titerTypeMenu = (
    <TextField select
               variant="standard"
               margin="dense"
               size="small"
               placeholder="Choose type..."
               value={state.type || ''}
               onChange={({ target: { value } }) => handleChange({ type: value })}
               sx={{ flex: 'auto', marginY: 0 }}
    >
      {
        currentTiterTypes?.map(({ name, id }, index) => {
          return (
            <MenuItem key={index} value={id}>
              {name}
            </MenuItem>
          );
        })
      }
    </TextField>
  );

  return (
    <Dialog onClose={handleClose}
            open={!!openedTiterDialogObject}
            maxWidth="md"
            transitionDuration={{ enter: 225, exit: 0 }}
    >
      {
        !!openedTiterDialogObject &&
        <DialogTitle sx={{ display: 'flex' }}>
          {state.isEditing ? 'Edit' : 'Add'}&nbsp;{
          currentTiterTypes?.length > 1
            ? titerTypeMenu
            : currentTiterTypes?.[0]?.name
        }&nbsp;titer
        </DialogTitle>
      }
      <DialogContent>
        <Stack direction="column" justifyContent="flex-start" spacing={2} marginX={1}>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Date:
            </Typography>
            <CustomDatePicker value={state.date}
                              disableFuture
                              handleInput={(newValue) => {
                                handleChange({ date: newValue })
                              }}
            />
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Result:
            </Typography>
            <TextField select
                       variant="standard"
                       margin="dense"
                       size="small"
                       value={state.result || ''}
                       onChange={({ target: { value } }) => handleChange({ result: value })}
            >
              <MenuItem value="POS">Positive</MenuItem>
              <MenuItem value="LOW">Low</MenuItem>
              <MenuItem value="EQUIV">Equivocal</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Value (unit):
            </Typography>
            <TextField variant="standard"
                       margin="dense"
                       size="small"
                       placeholder="e.g. 123 ml/c"
                       value={state.value || ''}
                       onChange={({ target: { value } }) => handleChange({ value: value })}
            />
          </Stack>
          <SingleAttachmentOld handleChange={handleChange}
                               attachmentKey="attachment"
                               attachment={state.attachment || ''}
                               type="titers"
                               objectUrl={state.attachmentObjectUrl}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}
                size="small"
                variant="text"
                color="neutral"
        >
          Discard and close
        </Button>
        <LoadingButton variant="text"
                       loading={isSubmitting}
                       onClick={handleSave}
                       disabled={!state.result && !state.attachment && !state.value && !state.date}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
