import { useStore } from '../store';
import shallow from 'zustand/shallow';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

export default function SingleAttachmentOld(props) {
  const { attachment, handleChange, objectUrl, attachmentKey, type, rid } = props;

  const [
    handleAttachmentChipClick,
  ] = useStore(
    (store) => [
      store.handleAttachmentChipClick,
    ],
    shallow
  );

  const name = attachment?.name || attachment?.filename;

  const handleFileDelete = (e) => {
    e.preventDefault();
    handleChange({ [attachmentKey]: undefined });
  };

  const handleClick = () => {
    if (!rid && !objectUrl) return;
    handleAttachmentChipClick({ rid, objectUrl, fileName: name, type });
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
    handleChange({
      [attachmentKey]: file,
      attachmentObjectUrl: URL.createObjectURL(file),
    });
  };

  return attachment
    ? (
      <div>
        <Chip clickable={!!rid || !!objectUrl}
              size="small"
              label={name}
              onClick={handleClick}
              onDelete={handleFileDelete}
              sx={{ maxWidth: '150px' }}
        />
      </div>
    ) : (
      <label>
        <Button variant="contained" size="small" component="span">
          Attach file
        </Button>
        <input hidden
               type="file"
               value={attachment}
               onChange={handleInputChange}
        />
      </label>
    );
};
