import { useStore as useTBStore } from './tuberculosis-store';
import shallow from 'zustand/shallow';
import { getPortalSettingsItem } from '../utils';
import questions from '../consts/tuberculosis-questionnaire.json';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function TuberculosisQuestionnaire() {
  const [
    tbHistoryData,
    onTbHistoryChange,
  ] = useTBStore(
    (store) => [
      store.tbHistoryData,
      store.onTbHistoryChange,
    ],
    shallow
  );

  const questionnaire = tbHistoryData.questionnaire || new Array(questions.length).fill(null);

  const handleRadioChange = (event, index) => {
    const newQuestionnaire = [...questionnaire];
    newQuestionnaire[index] = Number(event.target.value);

    onTbHistoryChange({
      ...tbHistoryData,
      questionnaire: newQuestionnaire,
    })
  };

  return (
    <Box>
      <h4 style={{ display: 'flex', justifyContent: 'center' }}>
        TUBERCULOSIS SCREENING QUESTIONNAIRE
      </h4>
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <Typography variant="overline" gutterBottom>
          Please answer the following questions:
        </Typography>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          {
            questions.map(({ value, key }, index) => (
              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} key={key}>
                <FormControl>
                  <RadioGroup row value={questionnaire[index]}
                              onChange={(e) => handleRadioChange(e, index)}>
                    <FormControlLabel
                      label="Yes"
                      value={1}
                      control={<Radio size="small"/>}
                    />
                    <FormControlLabel
                      label="No"
                      value={0}
                      control={<Radio size="small"/>}
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant="body2" sx={{ maxWidth: '80%', py: 1 }}>
                  {value}
                </Typography>
              </Box>
            ))
          }
        </Stack>
        <div dangerouslySetInnerHTML={{ __html: getPortalSettingsItem('tb_questionnaire') }}/>
      </Stack>
    </Box>
  );
}
