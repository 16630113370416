import axios from 'axios';
import { useStore } from '../store';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  config.customError = new Error('Thrown at:');
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      useStore.getState().clearUserToken();
    }
    error.stack = error.config?.customError?.stack;
    return Promise.reject(error);
  });

export default function buildRequest(mainRoute) {
  return async function ({ route = '', type = 'get', data, headers, responseType }) {
    const userToken = useStore.getState().userToken;
    const args = [`api/${mainRoute}/${route}`];
    if (data) args.push(data);
    const config = {
      headers: {
        'Authorization': `Bearer ${userToken}`,
        ...headers,
      }
    };
    if (responseType) {
      config.responseType = responseType;
    }
    args.push(config);
    const res = await instance[type](...args);
    return res.data;
  };
}
