import buildRequest from './index';

const request = buildRequest('attachments');

export async function getFile(key) {
  return request({
    route: `?key=${key}`,
    responseType: 'blob',
  });
}

export async function saveFiles(data) {
  // not currently in use
  return request({
    type: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}