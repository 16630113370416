import React from 'react';
import { useHistory } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';

const LIST_ITEMS = [
  // {
  //   path: '/admin',
  //   title: 'Settings',
  //   Icon: SettingsIcon,
  // },
  {
    path: '/admin/User',
    title: 'Users',
    Icon: PersonIcon,
  },
  {
    path: '/admin/VaccinationType',
    title: 'Vaccination types',
    Icon: FormatListNumbered,
  },
  {
    path: '/admin/Vaccination',
    title: 'Vaccinations',
    Icon: LocalHospitalIcon,
  },
  {
    path: '/admin/MeningitisWaiver',
    title: 'Meningitis waivers',
  },
  {
    path: '/admin/GeneralHealthQuestion',
    title: 'General health questions',
  },
  {
    path: '/admin/GeneralHealthAnswerOption',
    title: 'General health answer options',
  },
  {
    path: '/admin/GeneralHealthAnswer',
    title: 'General health answers',
  },
];

const ListItemComponent: React.FC = ({ path, title, Icon }) => {
  const router = useHistory();
  return (
    <ListItem
      button
      key={path}
      onClick={() => router.push(path)}
      selected={router.location.pathname === path}
    >
      <ListItemIcon>
        {
          Icon && <Icon/>
        }
      </ListItemIcon>
      <ListItemText primary={title}/>
    </ListItem>
  );
};

export const MainListItems: React.FC = () => {
  return (
    <>
      {LIST_ITEMS.map(ListItemComponent)}
    </>
  );
};
