import { useStore as useGeneralHealthStore } from './general-health-store';
import shallow from 'zustand/shallow';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

export default function QuestionMultipleChoice({ options, id, question }) {
  const [
    generalHealthAnswers,
    onGeneralHealthDataChange,
  ] = useGeneralHealthStore(
    (store) => [
      store.generalHealthAnswers,
      store.onGeneralHealthDataChange,
    ],
    shallow,
  );

  const values = generalHealthAnswers[id]?.value || [];

  const handleInputChange = (option) => (e) => {
    const newValue = [...values];
    if (e.target.checked) {
      newValue.push(option);
    } else {
      const index = values.indexOf(option);
      if (index === -1) {
        console.error('Error deleting answer option');
        return;
      }
      newValue.splice(index, 1);
    }
    onGeneralHealthDataChange({
      id,
      value: newValue,
    });
  };

  return (
    <>
      <Typography variant="body2">
        <strong>
          {question}
        </strong>
      </Typography>
      <FormControl>
        <FormGroup>
          {
            options?.map((option, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox checked={values.indexOf(option) !== -1}
                              onChange={handleInputChange(option)}
                              size="small"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {option}
                    </Typography>
                  }
                  key={index}
                />
              )
            })
          }
        </FormGroup>
      </FormControl>
    </>
  );
}