import { useStore } from '../store';
import shallow from 'zustand/shallow';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function SingleAttachment(props) {
  const { attachment, handleChange, objectUrl, identifier, text } = props;

  const [
    handleAttachmentChipClick,
  ] = useStore(
    (store) => [
      store.handleAttachmentChipClick,
    ],
    shallow
  );

  const name = attachment?.value?.name || attachment?.name;

  const handleFileDelete = (e) => {
    e.preventDefault();
    handleChange(undefined, identifier);
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
  };

  const handleClick = () => {
    if (!attachment?.key && !objectUrl) return;
    handleAttachmentChipClick({
      key: attachment.key,
      objectUrl,
      fileName: name,
      mimeType: attachment.value?.type || attachment.type,
    });
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
    handleChange(file, identifier);
  };

  return attachment
    ? (
      <div>
        <Chip clickable
              size="small"
              label={name}
              onClick={handleClick}
              onDelete={attachment?.key ? null : handleFileDelete}
              sx={{ maxWidth: '150px' }}
        />
      </div>
    ) : (
      <Stack direction="column" spacing={1} marginY={2}>
        {
          !!text &&
          <Typography variant="overline">
            {text}
          </Typography>
        }
        <label>
          <Button variant="contained" size="small" component="span">
            Attach file
          </Button>
          <input hidden
                 type="file"
                 value={attachment}
                 onChange={handleInputChange}
          />
        </label>
      </Stack>
    );
};
