import { useStore as useMeningitisStore } from './meningitis-store';
import shallow from 'zustand/shallow';
import { getPortalSettingsItem } from '../utils';
import SingleAttachment from './single-attachment';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomDatePicker from './custom-date-picker';
import TextField from '@mui/material/TextField';

export default function MeningitisWaiver() {
  const [
    previouslySubmittedMeningitisWaiver,
    meningitisWaiver,
    onMeningitisWaiverChange,
    handleAttachmentInput,
  ] = useMeningitisStore(
    (store) => [
      store.previouslySubmittedMeningitisWaiver,
      store.meningitisWaiver,
      store.onMeningitisWaiverChange,
      store.handleAttachmentInput,
    ],
    shallow,
  );

  const {
    attachment,
    attachmentObjectUrl,
    waiverSigned,
    waiverSignedUnder18,
    waiverSignedDate,
    waiverSignedName,
  } = meningitisWaiver || {};

  return (
    <Stack direction="column" spacing={1}>
      <div dangerouslySetInnerHTML={{ __html: getPortalSettingsItem('meningitis_waiver') }}/>
      <div>
        <FormControlLabel disabled={!!waiverSignedUnder18 || !!previouslySubmittedMeningitisWaiver.waiverSigned}
                          control={<Checkbox size="small"/>}
                          label={
                            <Typography variant="caption"
                                        dangerouslySetInnerHTML={{
                                          __html: getPortalSettingsItem('meningitis_waiver_signed_checkbox')
                                        }}
                            />
                          }
                          checked={waiverSigned || false}
                          onChange={() => onMeningitisWaiverChange({ waiverSigned: !waiverSigned })}
        />
        <FormControlLabel disabled={!!waiverSigned || !!previouslySubmittedMeningitisWaiver.waiverSignedUnder18}
                          control={<Checkbox size="small"/>}
                          label={
                            <Typography variant="caption"
                                        dangerouslySetInnerHTML={{
                                          __html: getPortalSettingsItem('meningitis_waiver_signed_under_18_checkbox')
                                        }}
                            />
                          }
                          checked={waiverSignedUnder18 || false}
                          onChange={() => onMeningitisWaiverChange({ waiverSignedUnder18: !waiverSignedUnder18 })}
        />
      </div>
      <Stack direction="row" justifyContent="space-around" flexWrap="wrap" gap={2}>
        <Stack direction="column" justifyContent="space-between" spacing={1}>
          <Typography variant="overline">
            Waiver Signed Name:
          </Typography>
          <TextField variant="standard"
                     size="small"
                     margin="normal"
                     autoComplete="off"
                     disabled={!!previouslySubmittedMeningitisWaiver.waiverSignedName}
                     value={waiverSignedName || ''}
                     onChange={(e) => onMeningitisWaiverChange({ waiverSignedName: e.target.value })}
          />
        </Stack>
        <Stack direction="column" justifyContent="space-between" spacing={1}>
          <Typography variant="overline">
            Waiver Signed Date:
          </Typography>
          <CustomDatePicker value={waiverSignedDate || null}
                            disableFuture
                            disabled={!!previouslySubmittedMeningitisWaiver.waiverSignedDate}
                            handleInput={(newValue) => {
                              onMeningitisWaiverChange({ waiverSignedDate: newValue })
                            }}
          />
        </Stack>
      </Stack>
      <div dangerouslySetInnerHTML={{ __html: getPortalSettingsItem('meningitis_waiver_attachment') }}/>
      <SingleAttachment handleChange={handleAttachmentInput}
                        attachment={attachment}
                        objectUrl={attachmentObjectUrl}
      />
    </Stack>
  );
}
