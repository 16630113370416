import create from 'zustand';
import consts from './consts';
import supportedImageMimeTypes from './consts/commonly-supported-image-mime-types.json';
import { editPerson, getPersonData } from './api/persons';
import { getFile } from './api/attachments';
import { sendLogToServer } from './api/service';
import { signIn } from './api/users';

const { LOADING_TIMEOUT, USER_TOKEN_KEY } = consts;

const useStore = create((set, get) => ({
  personData: null,
  previouslySubmittedPersonData: {},
  onPersonDataChange: (v) => set(({ personData }) => ({ personData: { ...personData, ...v } })),
  fetchPersonData: async () => {
    const personData = await getPersonData();
    set({ personData });
  },
  userToken: localStorage.getItem(USER_TOKEN_KEY),
  parseToken: (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  },
  signIn: async ({ email, password }) => {
    try {
      const userToken = await signIn({ email, password });
      localStorage.setItem(USER_TOKEN_KEY, userToken);
      set({ userToken });
    } catch (e) {
      console.error(e);
    }
  },
  clearUserToken: () => set(() => {
    localStorage.removeItem(USER_TOKEN_KEY);
    return { userToken: null };
  }),

  loadingStartTimeStamp: 0,
  checkLoadingTime: (willStartLoad) => set(({ loadingStartTimeStamp }) => {
    if (willStartLoad) return { loadingStartTimeStamp: Date.now() };
    const loadingTime = Date.now() - loadingStartTimeStamp;
    if (loadingTime > LOADING_TIMEOUT) {
      sendLogToServer({
        message: 'client loading timeout',
        details: { loadingTime },
      });
    }
    return { loadingStartTimeStamp: 0 };
  }),
  isLoading: false,
  setIsLoading: (v) => set(({ checkLoadingTime }) => {
    checkLoadingTime(v);
    return { isLoading: v };
  }),

  isAttachmentDialogOpened: false,
  attachmentDialogObject: {
    url: null,
    isImage: true,
    fileName: '',
    isLocal: false,
  },
  downloadAttachment: ({ fileName, objectUrl, isLocal }) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    if (!isLocal) {
      URL.revokeObjectURL(objectUrl);
    }
    set({ isLoading: false });
  },
  // we need either objectUrl or key to fetch a file blob and make an objectUrl to show a file
  handleAttachmentChipClick: async ({ key, objectUrl: objectUrlParam, fileName, mimeType }) => {
    const isImage = mimeType?.split('/').shift() === 'image';
    const isApplicableForDisplaying = [...supportedImageMimeTypes, 'application/pdf'].includes(mimeType);
    set({ attachmentDialogObject: { isImage }, isLoading: true });
    let objectUrl = objectUrlParam;
    if (!objectUrl) {
      try {
        const blob = await getFile(key);
        objectUrl = URL.createObjectURL(blob);
      } catch (e) {
        console.error(e);
        set({ isLoading: false });
        return;
      }
    }
    const isLocal = objectUrlParam;
    if (!isApplicableForDisplaying) {
      get().downloadAttachment({ objectUrl, fileName, isLocal });
      return;
    }
    set({
      isLoading: false,
      isAttachmentDialogOpened: true,
      attachmentDialogObject: {
        url: objectUrl,
        isImage,
        fileName,
        isLocal,
      },
    });
  },
  closeAttachmentDialog: () => set(({ attachmentDialogObject }) => {
    if (!attachmentDialogObject.isLocal) {
      URL.revokeObjectURL(attachmentDialogObject.url);
    }
    return {
      isAttachmentDialogOpened: false,
      attachmentDialogObject: {
        ...attachmentDialogObject, // to reduce blinking
        url: null,
        fileName: '',
      },
    };
  }),

  handleSubmitAuthorization: async () => {
    const { personData = {} } = get();
    const {
      isReleaseAuthorized,
      isDataAttested,
      signedName,
      signatureDate,
    } = personData;
    if (!isReleaseAuthorized || !isDataAttested || !signedName || !signatureDate) {
      const error = new Error();
      error.userMessage = 'You must complete all the fields';
      throw error;
    }
    await editPerson({
      isReleaseAuthorized,
      isDataAttested,
      signedName,
      signatureDate,
    });
  },
}));

export { useStore };
