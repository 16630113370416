import React from 'react';
import {
  useRouteMatch,
  Route,
} from 'react-router-dom';
import VaccinationType from './pages/VaccinationType';
import Vaccination from './pages/Vaccination';
import User from './pages/User';
import MeningitisWaiver from './pages/MeningitisWaiver';
import GeneralHealthQuestion from './pages/GeneralHealthQuestion';
import GeneralHealthAnswerOption from './pages/GeneralHealthAnswerOption';
import GeneralHealthAnswer from './pages/GeneralHealthAnswer';

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route path={`${path}/User`} component={User}/>
      <Route path={`${path}/VaccinationType`} component={VaccinationType}/>
      <Route path={`${path}/Vaccination`} component={Vaccination}/>
      <Route path={`${path}/MeningitisWaiver`} component={MeningitisWaiver}/>
      <Route path={`${path}/GeneralHealthQuestion`} component={GeneralHealthQuestion}/>
      <Route path={`${path}/GeneralHealthAnswerOption`} component={GeneralHealthAnswerOption}/>
      <Route path={`${path}/GeneralHealthAnswer`} component={GeneralHealthAnswer}/>
    </>
  );
};

export default Admin;
