import { useState, useMemo } from 'react';
import { useStore } from '../store';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { getPortalSettingsItem } from '../utils';

const styles = {
  linkButton: {
    my: 2, color: 'white', display: 'block', fontSize: '12.6px',
  }
};

function byteArrayToDataURI(byteArray, mimeType) {
  const dataArray = byteArray.map((byte) => String.fromCharCode(byte));
  const dataString = dataArray.join('');
  const base64String = btoa(dataString);
  return `data:${mimeType};base64,${base64String}`;
}

const logoObj = getPortalSettingsItem('logo');
const { file: { data }, mimeType } = logoObj;
const logoDataURI = byteArrayToDataURI(data, mimeType);

export default function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const [
    userToken,
    clearUserToken,
    parseToken,
  ] = useStore(
    (store) => [
      // store.personData,
      store.userToken,
      store.clearUserToken,
      store.parseToken,
    ],
    shallow,
  );

  const isAdmin = useMemo(
    () => {
      if (!userToken) {
        return;
      }
      const res = parseToken(userToken);
      return !!res.isAdmin;
    },
    [userToken, parseToken],
  );

  const pages = useMemo(
    () => {
      const _pages = [{
        text: 'Home',
        link: '/',
      }, {
        text: 'Vaccinations',
        link: '/vaccinations',
      }, {
        text: 'Profile',
        link: '/profile',
        condition: () => !!userToken,
      }, {
        text: 'Sign Out',
        link: '/',
        action: () => clearUserToken(),
        condition: () => !!userToken,
      }, {
        text: 'Sign In',
        link: '/sign-in',
        condition: () => !userToken,
      }];
      if (isAdmin) {
        _pages.push({
          text: 'Admin',
          link: '/admin',
        });
      }
      return _pages;
    },
    [isAdmin, userToken, clearUserToken],
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" enableColorOnDark sx={{ boxShadow: 'none', height: '64px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ fill: '#fff' }}
               component="a"
               title="Logo"
               href="/"
          >
            <img src={logoDataURI} style={{ height: '60px' }} alt=""/>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'end' }}>
            <IconButton onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon/>
            </IconButton>
            <Menu anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
            >
              {
                pages.reduce((filtered, { text, link, action, condition }) => {
                  if (typeof condition === 'undefined' || condition()) {
                    const item = (
                      <MenuItem key={text}
                                onClick={(e) => {
                                  handleCloseNavMenu(e);
                                  action?.();
                                }}
                      >
                        <Typography component={action ? 'div' : Link} to={link} color="inherit">
                          {text}
                        </Typography>
                      </MenuItem>
                    );
                    filtered.push(item);
                  }
                  return filtered;
                }, [])
              }
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
            {
              pages.reduce((filtered, { text, link, action, condition }) => {
                if (typeof condition === 'undefined' || condition()) {
                  const item = (
                    <Button key={text}
                            component={Link}
                            to={link}
                            onClick={() => {
                              action?.();
                            }}
                            sx={styles.linkButton}
                    >
                      {text}
                    </Button>
                  );
                  filtered.push(item);
                }
                return filtered;
              }, [])
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
