import { useState, useEffect } from 'react';
import { useStore as useTBStore } from './tuberculosis-store';
import shallow from 'zustand/shallow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SingleAttachmentOld from './single-attachment-old';
import CustomDatePicker from './custom-date-picker';

const TYPE = 'Blood';

const initialState = {
  dateAdministered: null,
  result: '',
  value: '',
  type: '',
  attachment: '',
  attachmentObjectUrl: null,
};

export default function TuberculosisBloodTestDialog() {
  const [
    openedTbBloodTestDialogIndex,
    setOpenedTbBloodTestDialogIndex,
    { tbTests = {} } = {},
    onTbHistoryChange,
  ] = useTBStore(
    (store) => [
      store.openedTbBloodTestDialogIndex,
      store.setOpenedTbBloodTestDialogIndex,
      store.tbHistoryData,
      store.onTbHistoryChange,
    ],
    shallow,
  );

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (openedTbBloodTestDialogIndex !== null) {
      setState({
        ...initialState,
        ...(tbTests.blood?.[openedTbBloodTestDialogIndex] || {}),
      });
    }
  }, [openedTbBloodTestDialogIndex, tbTests]);

  const handleChange = (valuesToMerge) => {
    setState({
      ...state,
      ...valuesToMerge,
    });
  };

  const handleClose = () => {
    setOpenedTbBloodTestDialogIndex(null);
    setState(initialState);
  };

  const handleSave = async () => {
    tbTests.blood[openedTbBloodTestDialogIndex] = {
      ...state,
      type: TYPE,
    };
    onTbHistoryChange({
      tbTests: {
        ...tbTests,
        blood: [
          ...tbTests.blood,
        ]
      }
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose}
            open={openedTbBloodTestDialogIndex != null}
            maxWidth="md"
            transitionDuration={{ enter: 225, exit: 0 }}
    >
      <DialogTitle>
        {tbTests.blood?.[openedTbBloodTestDialogIndex] ? 'Edit' : 'Add'} TB (Tuberculosis) Blood Test
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" justifyContent="flex-start" spacing={3} marginX={1}>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Date Administered: *
            </Typography>
            <CustomDatePicker value={state.dateAdministered}
                              disableFuture
                              handleInput={(newValue) => {
                                handleChange({ dateAdministered: newValue })
                              }}
            />
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Test type:
            </Typography>
            <TextField select
                       variant="standard"
                       margin="dense"
                       size="small"
                       value={state.bloodTestType || ''}
                       onChange={(e) => handleChange({ bloodTestType: e.target.value })}
            >
              <MenuItem value="Quantiferion Gold">Quantiferion Gold</MenuItem>
              <MenuItem value="T-Spot">T-Spot</MenuItem>
              <MenuItem value="IGRA">IGRA</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Result: *
            </Typography>
            <TextField select
                       variant="standard"
                       margin="dense"
                       size="small"
                       value={state.result || ''}
                       onChange={(e) => handleChange({ result: e.target.value })}
            >
              <MenuItem value="Positive">Positive</MenuItem>
              <MenuItem value="Negative">Negative</MenuItem>
            </TextField>
          </Stack>
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="overline">
              Value:
            </Typography>
            <TextField variant="standard"
                       margin="dense"
                       size="small"
                       value={state.value || ''}
                       onChange={(e) => handleChange({ value: e.target.value })}
            />
          </Stack>
          <SingleAttachmentOld handleChange={handleChange}
                               attachmentKey="attachment"
                               attachment={state.attachment || ''}
                               type="tb-tests"
                               objectUrl={state.attachmentObjectUrl}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}
                size="small"
                variant="text"
                color="neutral"
        >
          Discard and close
        </Button>
        <LoadingButton variant="text"
                       onClick={handleSave}
                       disabled={!state.result && !state.attachment && !state.value && !state.dateAdministered && !state.type}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}