import { useStore as vaccinationsStore } from './vaccinations-store';
import { useStore } from '../store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import VaccinationCard from './vaccination-card';
import VaccinationsAttachments from './vaccinations-attachments';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const { VACCINATION_TYPE_RID_COVID } = consts;

// COVID does not differ from other vaccinations in White label version of a portal (current version),
// therefore all related logic is not being used rn
export default function RequiredVaccinations() {
  const [
    personData,
  ] = useStore(
    (store) => [
      store.personData,
    ],
    shallow,
  );

  const [
    vaccinationTypes,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationTypes,
    ],
    shallow,
  );

  let covidCard = null;

  // TODO: filter this in the upper level
  const cards = vaccinationTypes.reduce((filtered, vaccinationType) => {
    if (vaccinationType.isOther) {
      return filtered;
    }
    if (!personData?.livesAtCampus && !personData?.attendsClassesAtCampus) {
      return filtered;
    }
    const isRequired = (personData?.role === 'Employee' && vaccinationType.requiredForEmployees)
      || (personData?.role === 'Student' && vaccinationType.requiredForStudents)
      || (personData?.livesAtCampus && vaccinationType.requiredForLivingAtCampus)
      || (personData?.attendsClassesAtCampus && vaccinationType.requiredForAttendingClassesAtCampus);

    if (isRequired) {
      if (vaccinationType.id === VACCINATION_TYPE_RID_COVID) {
        return filtered;
        // TODO
        // covidCard = (
        //   <VaccinationCard key={vaccinationType.id} {...vaccinationType}/>
        // );
      } else {
        filtered = [
          ...filtered,
          <VaccinationCard key={vaccinationType.id} {...vaccinationType}/>
        ];
      }
    }
    return filtered;
  }, []);

  if (covidCard) {
    cards.unshift(covidCard);
  }

  const isThereOnlyCovid = !!covidCard && cards.length === 1;

  // TODO: remove step when no vaccinations
  const noVaccinations = (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="button">
        No required vaccinations
      </Typography>
    </Box>
  );

  return (
    <>
      {
        !!cards.length
          ? (
            <>
              {
                !isThereOnlyCovid && (
                  <>
                    <VaccinationsAttachments/>
                    <br/>
                  </>
                )
              }
              {cards}
            </>
          ) : noVaccinations
      }
    </>
  );
}
