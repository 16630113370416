import create from 'zustand';
import { getWaiver, saveWaiver } from '../api/meningitis-waivers';
import mime from 'mime';

const useStore = create((set, get) => ({
  meningitisWaiver: {},
  previouslySubmittedMeningitisWaiver: {},

  fetchMeningitisWaiver: async () => {
    const { data = {}, attachment } = await getWaiver();
    const meningitisWaiver = {
      ...data,
    };
    if (attachment) {
      const attachmentKey = attachment;
      meningitisWaiver.attachment = {
        key: attachmentKey,
        value: {
          name: attachmentKey.split('/').pop(),
          type: mime.getType(attachmentKey?.split('.').pop()),
        },
      }
    }
    set({
      meningitisWaiver: {
        ...meningitisWaiver,
        waiverSignedDate: meningitisWaiver.waiverSignedDate || new Date(),
      },
      previouslySubmittedMeningitisWaiver: meningitisWaiver,
    });
  },

  onMeningitisWaiverChange: (valuesToMerge) => set((state) => {
    return {
      meningitisWaiver: {
        ...state.meningitisWaiver,
        ...valuesToMerge,
      }
    };
  }),

  handleAttachmentInput: (file) => set((state) => {
    const _attachmentProps = {
      attachment: file,
    };
    if (file) {
      _attachmentProps.attachmentObjectUrl = URL.createObjectURL(file);
    }
    return {
      meningitisWaiver: {
        ...state.meningitisWaiver,
        ..._attachmentProps,
      },
    };
  }),

  handleSubmitMeningitisWaiver: async () => {
    const { meningitisWaiver, fetchMeningitisWaiver } = get();
    if (!meningitisWaiver.waiverSigned && !meningitisWaiver.waiverSignedUnder18) {
      const error = new Error();
      error.userMessage = 'You must select a sign option';
      throw error;
    }
    await saveWaiver(meningitisWaiver);
    if (meningitisWaiver.attachmentObjectUrl) {
      URL.revokeObjectURL(meningitisWaiver.attachmentObjectUrl);
    }
    fetchMeningitisWaiver();
  },
}));

export { useStore };
