import buildRequest from './index';

const request = buildRequest('users');

export async function signIn({ email, password }) {
  return request({
    route: 'sign-in',
    type: 'post',
    data: { email, password },
  });
}
