import { useStore as useGeneralHealthStore } from './general-health-store';
import shallow from 'zustand/shallow';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

export default function QuestionSingleChoice({ options, id, question }) {
  const [
    generalHealthAnswers,
    onGeneralHealthDataChange,
  ] = useGeneralHealthStore(
    (store) => [
      store.generalHealthAnswers,
      store.onGeneralHealthDataChange,
    ],
    shallow,
  );

  const value = generalHealthAnswers[id]?.value;

  const handleInputChange = (e) => {
    onGeneralHealthDataChange({ id, value: e.target.value });
  };

  return (
    <>
      <Typography variant="body2">
        <strong>
          {question}
        </strong>
      </Typography>
      <FormControl>
        <RadioGroup value={value || ''} onChange={handleInputChange}>
          {
            options?.map((option, index) => {
              return (
                <FormControlLabel value={option}
                                  control={<Radio size="small"/>}
                                  label={
                                    <Typography variant="body2">
                                      {option}
                                    </Typography>
                                  }
                                  key={index}
                />
              );
            })
          }
        </RadioGroup>
      </FormControl>
    </>
  );
}