import { useStore } from '../store';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import VaccinationCard from './vaccination-card';
import VaccinationsAttachments from './vaccinations-attachments';
import OtherVaccinations from '../components/other-vaccinations';

const { VACCINATION_TYPE_RID_COVID } = consts;

export default function RecommendedVaccinations() {
  const [
    personData = {},
  ] = useStore(
    (store) => [
      store.personData,
    ],
    shallow,
  );

  const [
    vaccinationTypes,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationTypes,
    ],
    shallow
  );

  return (
    <>
      <VaccinationsAttachments/>
      <br/>
      {
        vaccinationTypes.reduce((filtered, vaccinationType) => {
          if (vaccinationType.isOther) {
            return filtered;
          }
          const isRequired = (personData?.livesAtCampus || personData?.attendsClassesAtCampus)
            && (
              (personData?.role === 'Employee' && vaccinationType.requiredForEmployees)
              || (personData?.role === 'Student' && vaccinationType.requiredForStudents)
              || (personData?.livesAtCampus && vaccinationType.requiredForLivingAtCampus)
              || (personData?.attendsClassesAtCampus && vaccinationType.requiredForAttendingClassesAtCampus)
            );

          if (!isRequired) {
            if (vaccinationType.id === VACCINATION_TYPE_RID_COVID) {
              return filtered;
              // TODO
              // filtered = [
              //   <VaccinationCard key={vaccinationType.id}
              //                    {...vaccinationType}
              //                    showExemptionCheckbox={false}
              //   />,
              //   ...filtered,
              // ];
            } else {
              filtered = [
                ...filtered,
                <VaccinationCard key={vaccinationType.id}
                                 {...vaccinationType}
                                 showExemptionCheckbox={false}
                />
              ];
            }
          }
          return filtered;
        }, [])
      }
      <OtherVaccinations/>
    </>
  );
}
