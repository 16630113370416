import { useStore } from '../store';
import shallow from 'zustand/shallow';
import { getPortalSettingsItem } from '../utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CustomDatePicker from './custom-date-picker';

export default function Authorization() {
  const [
    personData = {},
    onPersonDataChange,
    previouslySubmittedPersonData,
  ] = useStore(
    (store) => [
      store.personData,
      store.onPersonDataChange,
      store.previouslySubmittedPersonData,
    ],
    shallow,
  );

  const {
    isReleaseAuthorized,
    isDataAttested,
    signedName,
    signatureDate,
  } = personData || {};

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: getPortalSettingsItem('authorization_for_release_header') }}/>
      <FormControlLabel control={<Checkbox size="small"/>}
                        label={
                          <Typography variant="caption"
                                      dangerouslySetInnerHTML={{
                                        __html: getPortalSettingsItem('authorization_checkbox')
                                      }}
                          />
                        }
                        disabled={!!previouslySubmittedPersonData.isReleaseAuthorized}
                        checked={!!isReleaseAuthorized}
                        onChange={() => onPersonDataChange({ isReleaseAuthorized: !isReleaseAuthorized })}
      />
      <br/>
      <FormControlLabel control={<Checkbox size="small"/>}
                        label={
                          <Typography variant="caption"
                                      dangerouslySetInnerHTML={{
                                        __html: getPortalSettingsItem('attesting_checkbox')
                                      }}
                          />
                        }
                        disabled={!!previouslySubmittedPersonData.isDataAttested}
                        checked={!!isDataAttested}
                        onChange={() => onPersonDataChange({ isDataAttested: !isDataAttested })}
      />
      <br/><br/>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <Typography variant="overline" gutterBottom>
            Name:
          </Typography>
          <TextField variant="standard"
                     size="small"
                     margin="normal"
                     autoComplete="off"
                     disabled={!!previouslySubmittedPersonData.signedName}
                     value={signedName || ''}
                     onChange={(e) => onPersonDataChange({ signedName: e.target.value })}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="overline" gutterBottom>
            Date:
          </Typography>
          <CustomDatePicker value={signatureDate || null}
                            disableFuture
                            disabled={!!previouslySubmittedPersonData.signatureDate}
                            handleInput={(newValue) => {
                              onPersonDataChange({ signatureDate: newValue })
                            }}
          />
        </Stack>
      </Stack>
    </>
  );
}
