import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from '../store';
import { useStore as notificationStore } from '../components/notification-store';
import shallow from 'zustand/shallow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  const [
    signIn,
  ] = useStore(
    (store) => [
      store.signIn,
    ],
    shallow,
  );

  const [
    showNotification,
  ] = notificationStore(
    (store) => [
      store.showNotification,
    ],
    shallow,
  );

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!email || !password) {
        showNotification({ type: 'error', message: 'Please, fill all the fields' });
        return;
      }
      await signIn({ email, password });
      history.replace('/vaccinations');
    } catch (e) {
      showNotification({ type: 'error', message: 'Error signing in' });
      console.error(e);
    }
  };

  return (
    <Card
      variant="outlined"
      component="form"
      onSubmit={handleSubmit}
      sx={{ margin: 'auto' }}
    >
      <CardContent>
        <Typography variant="h5" align="center">
          Sign In
        </Typography>
        <TextField
          fullWidth
          variant="standard"
          type="email"
          label="Email"
          margin="normal"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          variant="standard"
          type="password"
          label="Password"
          margin="normal"
          autoComplete="off"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </CardContent>
      <CardActions>
        <Button
          type="submit"
          variant="contained"
          sx={{ float: 'right', margin: '10px' }}
        >
          Sign In
        </Button>
      </CardActions>
    </Card>
  );
}
