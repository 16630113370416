import { useStore as useGeneralHealthStore } from './general-health-store';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import QuestionMultipleChoice from './question-multiple-choice';
import QuestionSingleChoice from './question-single-choice';
import QuestionText from './question-text';
import QuestionCheckbox from './question-checkbox';

export default function GeneralHealthQuestionnaire() {
  const [
    questions,
  ] = useGeneralHealthStore(
    (store) => [
      store.generalHealthQuestions,
    ],
    shallow,
  );

  return (
    <Box>
      <h4 style={{ display: 'flex', justifyContent: 'center' }}>
        GENERAL HEALTH QUESTIONNAIRE
      </h4>
      <br/>
      <h5 style={{ display: 'flex', justifyContent: 'center' }}>
        Please answer the following questions:
      </h5>
      <br/>
      <Stack direction="column" alignItems="flex-start" spacing={4}>
        {
          questions.map(({ question, options, type, id, fileAddon, fileDir }) => {
              const Question = {
                text: QuestionText,
                singleChoice: QuestionSingleChoice,
                multiChoice: options?.length
                  ? QuestionMultipleChoice
                  : QuestionCheckbox,
              }[type];
              return (
                <Question
                  key={id}
                  id={id}
                  options={options}
                  question={question}
                  fileAddon={fileAddon}
                  fileDir={fileDir}
                />
              );
            }
          )
        }
      </Stack>
    </Box>
  );
}
