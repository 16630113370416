import buildRequest from './index';

const request = buildRequest('general-health');

export async function getAnswers() {
  return request({});
}

export async function saveAnswers(data) {
  return request({
    type: 'put',
    data,
  });
}

export async function getQuestions() {
  return request({
    route: 'questions',
  });
}

export async function getAttachments() {
  return request({
    route: 'attachments',
  });
}

export async function saveAttachments(data) {
  return request({
    route: 'attachments',
    type: 'put',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}