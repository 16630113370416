import React from 'react';
import NavBar from '../components/nav-bar';
import Footer from '../components/footer';
import Notification from '../components/notification';

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>
    <NavBar />
    <Notification />
    { children }
    <Footer />
  </>
);

export default MainLayout;