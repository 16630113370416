import { useState } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import AttachmentsChips from './attachments-chips';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const { MAX_CARD_ATTACHMENTS_COUNT } = consts;
const id = 'attachments';

export default function VaccinationsAttachments() {
  const [
    vaccinationTypes,
    vaccinationsData,
    handleAttachmentsInput,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationTypes,
      store.vaccinationsData,
      store.handleAttachmentsInput,
    ],
    shallow,
  );

  const [attachments, setAttachments] = useState('');

  const MAX_ATTACHMENTS_COUNT = MAX_CARD_ATTACHMENTS_COUNT * vaccinationTypes.length;
  // TODO: id = attachments + .attachments ???
  const isAttachmentButtonDisabled = vaccinationsData[id]?.attachments?.length >= MAX_ATTACHMENTS_COUNT;

  const handleFilesUpload = ({ target: { files } }) => {
    handleAttachmentsInput({ files });
    setAttachments('');
  };

  return (
    <Stack direction="column" spacing={1} marginY={2}>
      <Typography variant="overline">
        If you have one available, please attach a copy of your vaccination record for storage and retrieval
        whenever you need it
      </Typography>
      <Stack direction="column" alignItems="center" spacing={1} marginY={2}>
        <label htmlFor={`attachment-${id}`}>
          <Button variant="contained" size="small" component="span" disabled={isAttachmentButtonDisabled}>
            Upload files
          </Button>
          <input hidden
                 multiple
                 disabled={isAttachmentButtonDisabled}
                 id={`attachment-${id}`}
                 type="file"
                 value={attachments}
                 onChange={handleFilesUpload}
          />
        </label>
        <AttachmentsChips isCommon={true} attachmentsKey={id}/>
      </Stack>
    </Stack>
  );
}
