import { useStore } from '../store';
import { useStore as useVaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

// vaccinations and exemptions attachments chips - maybe rename or refactor in some way
export default function AttachmentsChips(props) {
  const { attachmentsKey, id, isCommon = false } = props;

  const [
    handleAttachmentChipClick,
  ] = useStore(
    (store) => [
      store.handleAttachmentChipClick,
    ],
    shallow
  );

  const [
    vaccinationsData,
    vaccinationsAttachments,
    newVaccinationsAttachments,
    deleteAttachment,
  ] = useVaccinationsStore(
    (store) => [
      store.vaccinationsData,
      store.vaccinationsAttachments,
      store.newVaccinationsAttachments,
      store.deleteAttachment,
    ],
    shallow
  );

  const filterFunc = (curr) => isCommon && !id ? !curr.id : curr.id === id;
  const attachments = isCommon ? newVaccinationsAttachments.filter(filterFunc) : vaccinationsData[id]?.[attachmentsKey];
  const moreAttachments = isCommon && vaccinationsAttachments.filter(filterFunc);
  // const isExemptions = attachmentsKey === 'exemptionsAttachments';

  const handleFileDelete = (index) => {
    deleteAttachment({ id, index, attachmentsKey, isCommon });
  };

  const handleClick = ({ key, objectUrl, name, type }) => () => {
    handleAttachmentChipClick({
      key,
      objectUrl,
      fileName: name,
      mimeType: type,
    });
  };

  return (
    <Stack direction="row" alignItems="start" display="flex" flexWrap="wrap" gap={1}>
      {
        moreAttachments?.length
          ? moreAttachments.map(({ value: { name, type }, key }) => {
            return (
              <Chip key={name}
                    label={name}
                    onClick={handleClick({ key, name, type })}
                    size="small"
                    clickable
              />
            );
          })
          : null
      }
      {
        attachments?.length
          ? attachments.map((item, index) => {
            const { name, objectUrl, key, value } = item;
            const label = name || value?.name;
            return (
              <Chip key={label}
                    label={label}
                    onClick={handleClick({ objectUrl, name: label, type: value?.type })}
                    onDelete={key ? null : () => handleFileDelete(index)}
                    size="small"
                    clickable
              />
            );
          })
          : null
      }
    </Stack>
  );
}
