import { useStore as useGeneralHealthStore } from './general-health-store';
import shallow from 'zustand/shallow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import SingleAttachment from '../single-attachment';
import { useMemo } from 'react';

export default function QuestionCheckbox({ id, question, fileDir }) {
  const [
    generalHealthAnswers,
    generalHealthAttachments,
    generalHealthAttachmentsNew,
    onGeneralHealthDataChange,
    handleSingleAttachmentInput,
  ] = useGeneralHealthStore(
    (store) => [
      store.generalHealthAnswers,
      store.generalHealthAttachments,
      store.generalHealthAttachmentsNew,
      store.onGeneralHealthDataChange,
      store.handleSingleAttachmentInput,
    ],
    shallow,
  );

  const value = !!generalHealthAnswers[id]?.value;

  const { attachment = undefined, objectUrl } = useMemo(
    () => {
      if (!fileDir) {
        return {};
      }
      const oldAttachment = generalHealthAttachments.find((curr) => {
        return curr.fileDir === fileDir;
      });
      if (oldAttachment) {
        return {
          attachment: oldAttachment,
        };
      }
      const newAttachment = generalHealthAttachmentsNew.find((curr) => {
        return curr.fileDir === fileDir;
      });
      if (newAttachment) {
        return {
          attachment: newAttachment.file,
          objectUrl: newAttachment.objectUrl,
        };
      }
      return {};
    },
    [generalHealthAttachments, generalHealthAttachmentsNew, fileDir],
  );

  const handleInputChange = (e) => {
    onGeneralHealthDataChange({ id, value: e.target.checked });
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox checked={value}
                    onChange={handleInputChange}
                    size="small"
          />
        }
        label={
          <Typography variant="body2">
            <strong>
              {question}
            </strong>
          </Typography>
        }
      />
      {
        !!((fileDir && value) || attachment) &&
        <SingleAttachment handleChange={handleSingleAttachmentInput}
                          attachment={attachment}
                          objectUrl={objectUrl}
                          identifier={fileDir}
                          text="Please upload copy of the related report"
        />
      }
    </div>
  );
}